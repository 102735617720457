<template>
  <v-dialog
    v-model="showSuccess"
    persistent
    max-width="400"
    scrollable
    class="rounded-0"
  >
  <v-card>
    <v-card-text class="pa-12">
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center align-center"
        >
          <v-icon
            size="160"
            color="icono"
          >
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-column justify-center align-center"
        >
          <span class="text-h3 font-weight-bold primary--text">Cobro Registrado</span>
          <span class="font-italic text-center font-weight-regular">
            El cobro se ha registrado exitosamente bajo el <strong>{{ cobro }}</strong>
          </span>
          <v-btn
            dark
            color="icono"
            block
            class="mt-6"
            @click="showSuccess = false"
          >
            Finalizar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SuccessPago',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cobro: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      showSuccess: this.value,
    }
  },
   watch: {
    showSuccess (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.showSuccess = val
    },
  },
}
</script>
